<template>



<div >
  <!-- Nav tabs -->
<ul class="nav nav-tabs" >
  <li class="nav-item">
    <a class="nav-link active" data-toggle="tab" href="#home">New Employee/Requester</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" data-toggle="tab" href="#menu1">Employees/Requesters Table </a>
  </li>
</ul>

<!-- Tab panes -->
<div class="tab-content">
  <div class="tab-pane container active" id="home">
    <div class="assetcontainer">
 <div class="row"> 
    
    <div class="col-md">
     <form method="POST">
        <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Employee No    :</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"   >
    </div>
  </div>
  <div class="form-group row">
    <label for="inputPassword" class="col-sm-2 col-form-label">Employee/Req. Name    :</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"   >
    </div>
  </div>
<div class="form-group row">
    <label for="inputPassword" class="col-sm-2 col-form-label">Site    :</label>
    <div class="col-sm-10">
      <select style="width:705px;">
        <option> Veto</option>
      </select>  <input style="width:700px;margin-left: 30px;max-width: 48.8%;px;" type="text" id="country" name="country" value="" readonly>
    </div>
  </div>

  <div class="form-group row">
    <label for="inputPassword" class="col-sm-2 col-form-label">Department   :</label>
    <div class="col-sm-10">
      <select style="width:705px;">
        <option> Veto</option>
      </select>  <input style="width:700px;margin-left: 30px;max-width: 48.8%;px;" type="text" id="country" name="country" value="" readonly>
    </div>
  </div>
 <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Mobile   :</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"   >
    </div>
  </div>
   <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Email    :</label>
    <div class="col-sm-10">
      <input type="email" class="form-control"  placeholder="Enter email">
  </div>
  </div>
  <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Address   :</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"   >
    </div>
  </div>

  <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Hourly Salary   :</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"   >
    </div>
  </div>

  <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Over Time Rate 1   :</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"   >
    </div>
  </div>

  <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Over Time Rate 2   :</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"   >
    </div>
  </div>

  <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Over Time Rate 3   :</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"   >
    </div>
  </div>

<div class="form-group row">
    <label for="inputPassword" class="col-sm-2 col-form-label">Category    :</label>
      <div class="col-sm-10">
      <select style="width:1445px;">
        <option> Veto</option>
      </select> 
    </div>
  </div>

  

 </form>
 </div>


</div>
</div>

  <!-- /////////////////////////////////////// BUTTON ///////////////////////// -->
  <p align="right">
  <button style="" type="button" class="btn btn-success">Save</button>
</p>

     
     </div>
  <div class="tab-pane container fade" id="menu1">
    
      <MessagesTable   :ays="content" />
   
  </div>
  <div class="tab-pane container fade" id="menu2"> <Visa /></div>
  <div class="tab-pane container fade" id="ayman">
   
  </div>
  </div>




</div>
</template>
<script>
//  
import Visa from '@/components/Visa.vue'
import UserService from "../services/user.service";
import MessagesTable from "@/components/MessagesTable";
export default {
  name: 'TabSafetyInstructions',
  data() {
    return {
      content: '',

      user:'',


    };
  },
    components: {MessagesTable,Visa} ,
   mounted(){
   UserService.getMessages().then(res => {
    this.content = res.data;
           }).catch(error => {
        console.error(error);
      })
   },
   methods:{
   Getit(){
    UserService.getMessages().then(res => {
    this.content = res.data;
           })
      .catch(error => {
        console.error(error);
      });
 }

}

}




</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#menu1{
  margin-top: 50px;
}
#visa {
  margin: 5px auto;
  max-width: 700px;
}
label{
  display: block;
  margin: 0px 0 0px;
}
input {
  font-size:18px;
  border: 1px double rgb(102, 97, 96) ;
  border-radius: 4px;
  
}
button {
  font-size: 16px;
 background: rgb(64, 179, 140);
  padding: 0.4rem 1.3rem;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 2px;
 margin: 25px;
 
}
span{
  width: 5px;
  float: right;
  cursor: pointer;
}
span:hover{
  color: brown;
}
.previous{
  border: 1.5px solid;
  padding:5px;
  margin-bottom: 2px;
}
.assetcontainer{
  margin-top: 50px;
  margin-left: 10px;
}
</style>